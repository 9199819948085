<template>
  <div class="w-100 p-2 mt-2">
    <page-header>
      <import-modal ref="import-modal"></import-modal>
      <export-modal ref="export-modal"></export-modal>
      <i class="fas fa-list"></i>
      {{ $t('page.title.cms') }}
      <template v-slot:right>
        <div class="d-flex justify-content-end w-50">
          <b-button @click="importModal" :disabled="isEditResource" size="md" class="ml-1 w-50" variant="warning">{{ $t('btn.import_cms') }}</b-button>
          <b-button @click="exportModal" :disabled="isEditResource" size="md" class="ml-1 w-50" variant="primary">{{ $t('btn.export_cms') }}</b-button>
          <b-button @click="goToCreateResourcePage" :disabled="isEditResource" size="md" class="ml-1 w-50" variant="success">{{ $t('btn.add_new') }}</b-button>
        </div>
      </template>
    </page-header>
    <div class="row">
      <div class="col-3">
        <div class="card">
          <div class="w-100 card-header border-0 rounded d-flex align-items-center justify-content-between">
            <h3 class="text-bold card-title w-75">{{ $t('cms.tree_title') }}</h3>
              <b-form-select
                  id="locale"
                  v-model="locale"
                  :options="localeList"
                  value-field="name"
                  text-field="name"
                  class="w-auto ml-5"
              ></b-form-select>
          </div>
          <div class="row d-flex pt-3 pb-3 pl-3 pr-2 m-1 bg-light">
            <div class="col-4 p-0">
              <b-form-select
                  id="locale"
                  v-model="treeAttribute"
                  :options="treeAttributesList"
                  value-field="key"
                  text-field="name"
              ></b-form-select>
            </div>
            <div class="col-8 d-flex justify-content-end">
              <b-form-input v-model="treeFilterPhrase" :placeholder="$t('cms.search_tree_phrase_placeholder')"></b-form-input>
            </div>
          </div>
          <TreeItem v-if="treeData && filteredTreeStructure" class="item pt-3" :model="filteredTreeStructure"></TreeItem>
        </div>
      </div>
      <div class="col-9">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PageHeader from "../../../components/page/PageHeader";
import TreeItem from "../components/TreeItem";
import ImportModal from "../components/ImportModal";
import ExportModal from "../components/ExportModal";

export default {
  components: {
    TreeItem,
    PageHeader,
    ImportModal,
    ExportModal
  },
  data() {
    return {
      treeAttributesList: [
        {key: 'title', name: this.$t('cms.select.title')},
        {key: 'keyName', name: this.$t('cms.select.key_name')},
        {key: 'slug', name: this.$t('cms.select.slug')},
      ]
    }
  },
  computed: {
    ...mapState({
      treeData: (state) => state.cms.resourceStructure ? state.cms.resourceStructure[0] : null,
      treeAttribute: (state) => state.cms.treeAttribute,
      resourceDetails: (state) => state.cms.resourceDetails,
      localeList: (state) => state.cms.localeList,
      locale: (state) => state.cms.locale,
      group: (state) => state.cms.group,
      activeResourceId: (state) => state.cms.activeResourceId,
      isEditResource: (state) => state.cms.isEditResource,
      resourceParentId: (state) => state.cms.resourceDetails.parentId,
      activeResourceParentId: (state) => state.cms.activeResourceParentId,
      userEmail: (state) => state.root.user.login
    }),
    localeFromUrl() {
      return this.$route.params.locale;
    },
    treeFilterPhrase: {
      get() {
        return this.$store.state.cms.treeFilterPhrase;
      },
      set(value) {
        this.$store.commit('cms/SET_TREE_FILTER_PHRASE', value)
      }
    },
    locale: {
      get() {
        return this.$store.state.cms.locale;
      },
      set(value) {
        this.$store.commit('cms/SET_LOCALE', value)
      }
    },
    treeAttribute: {
      get() {
        return this.$store.state.cms.treeAttribute;
      },
      set(value) {
        this.$store.commit('cms/SET_TREE_ATTRIBUTE', value)
      }
    },
    filteredTreeStructure() {
      const copiedTree = JSON.parse(JSON.stringify(this.treeData));

      if(copiedTree?.children && this.treeFilterPhrase) {
        const filteredTreeData = copiedTree.children.filter(this.filterTree);

        return {
          children: [...filteredTreeData]
        };
      }

      return this.treeData;
    }
  },
  watch: {
    locale() {
      this.$store.commit('cms/SET_RESOURCE_STRUCTURE', null);

      this.$store.dispatch('cms/getResourceStructure', {group: this.group, locale: this.locale}).then(() => {
        if(!this.localeFromUrl) {
          this.$router.push({ name: 'resources-list' });
        }
        this.$store.commit('cms/SET_PARENT_ID_TO_OPEN_TREE', this.resourceDetails.parentId);
      });
    },
    group() {
      this.$store.dispatch('cms/getResourceStructure', {group: this.group, locale: this.locale}).then(() => {
        this.$router.push({ name: 'resources-list' })
      });
    }
  },
  mounted() {
    this.$store.dispatch('cms/getResourceStructure', {group: 'default', locale: this.locale});
    this.$store.dispatch('cms/getLocale');
    this.$store.dispatch('cms/getAvailableParents');

    if(this.$route.params.id) {
      this.getResource(this.$route.params.id);
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.getResource(to.params.id);
    next();
  },
  methods: {
    getResource(resourceId) {
      if(resourceId && (!this.resourceDetails || resourceId !== this.resourceDetails.id)) {
        this.$store.commit('cms/SET_IS_LOADING', true);
        this.$store.dispatch('cms/getResourceDetails', resourceId);
        this.$store.dispatch('cms/getResourceFile', resourceId);
      }
    },
    goToCreateResourcePage() {
      this.$store.commit('cms/SET_SHOW_VALIDATION_STATUS', false);
      this.$store.commit('cms/SET_RESOURCE_DETAILS_INITIAL_VALUE');
      this.$store.commit('cms/SET_RESOURCE_FORM_AUTHOR', this.userEmail);
      this.$store.commit('cms/SET_RESOURCE_FORM_FILE', null);
      this.$store.dispatch('cms/getAvailableParents', this.locale);
      this.$store.commit('cms/SET_RESOURCE_FORM_STATUS', 'draft');
      this.$store.commit('cms/SET_RESOURCE_TRANSLATIONS', null);
      this.$store.commit('cms/SET_RESOURCE_CHILD_ELEMENTS', null);

      if(this.resourceParentId) {
        this.$store.commit('cms/SET_RESOURCE_FORM_PARENT_ID', this.resourceParentId);
      }

      if(this.activeResourceParentId !== null) {
        this.$store.commit('cms/SET_RESOURCE_FORM_PARENT_ID', this.activeResourceId);
      }

      this.$router.push({ name: 'cms-general-create' });
    },
    filterTree(item) {
      if (item[this.treeAttribute].toLowerCase().includes(this.treeFilterPhrase.toLowerCase())) return true;

      if (item.children) {
        return (item.children = item.children.filter(this.filterTree)).length;
      }
    },
    exportModal() {
      this.$refs['export-modal'].showModal();
    },
    importModal() {
      this.$refs['import-modal'].showModal();
    }
  },
}
</script>

<style>
.item {
  cursor: pointer;
  line-height: 1.5;
}
.bold {
  font-weight: bold;
}
ul {
  padding-left: 22px;
}
li {
  list-style: none;
}
.item-hover-effect {
  transition: 0.2s;
}
.item-hover-effect:hover {
  color: #007bff;
}
.tree-text-muted {
  color: #cdcdcd;
}
</style>