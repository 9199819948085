<template>
  <li>
    <div
        @dblclick="changeType" class="d-flex align-items-center item-hover-effect">
      <span v-if="!isRootElement && model.id !== -1 && isFolder" @click="toggle(model.id)" >
        <b-icon-folder v-if="!isOpen" class="h6 mr-2"></b-icon-folder>
        <b-icon-folder2-open v-if="isOpen" class="h6 mr-2"></b-icon-folder2-open>
      </span>

      <b-icon-file-earmark-text v-if="!isRootElement && model.id && !isFolder" @click="getData(model.id)" class="h6 mr-2"></b-icon-file-earmark-text>
      <span v-if="model[treeAttribute]" @click="getData(model.id)" :class="{ bold: isActiveResource, 'tree-text-muted': model.status === 'draft' }" style="padding-bottom: 8px">
        {{ model[treeAttribute].substring(0,25) }}{{ model[treeAttribute].length > 25 ? '...' : '' }}
      </span>
    </div>
    <ul v-show="isOpen" v-if="isFolder">
      <TreeItem
          :ref="model.id"
          class="item"
          v-for="(model, index) in model.children"
          :key="index"
          :model="model">
      </TreeItem>
    </ul>
  </li>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'TreeItem', // necessary for self-reference
  props: {
    model: Object
  },
  data() {
    return {
      isOpen: this.model.id === -1 ? true : false
    }
  },
  watch: {
    idFromUrl(newValue) {
      if(newValue) {
        this.$store.commit('cms/SET_ACTIVE_RESOURCE_ID', newValue);
      }
    },
    parentIdToOpenTree(newValue) {
      if(newValue && newValue === this.model.id) {
        this.toggleAutomatic(this.model.id);
      }
    },
    treeFilterPhrase(newValue) {
      if(newValue) {
        this.isOpen = true;
      } else if(!this.isRootElement) {
        this.isOpen = false;
      }
    }
  },
  beforeMount() {
    this.$store.commit('cms/SET_ACTIVE_RESOURCE_ID', this.$route.params.id);
  },
  computed: {
    ...mapState({
      treeAttribute: (state) => state.cms.treeAttribute,
      activeResourceId: (state) => state.cms.activeResourceId,
      parentIdToOpenTree: (state) => state.cms.parentIdToOpenTree,
      treeFilterPhrase: (state) => state.cms.treeFilterPhrase
    }),
    localeFromUrl() {
      return this.$route.params.locale;
    },
    isFolder() {
      return this.model.children?.length;
    },
    isActiveResource() {
      return this.activeResourceId === this.model.id;
    },
    isRootElement() {
      return !this.model.id || this.model.id === -1;
    },
    idFromUrl() {
      return this.$route.params.id;
    }
  },
  methods: {
    setIsEditResource() {
      this.$store.commit('cms/SET_IS_EDIT_RESOURCE', false);
    },
    getData(resourceId) {
      this.$store.commit('cms/SET_SHOW_VALIDATION_STATUS', false);
      this.setIsEditResource();

      this.$store.commit('cms/SET_ACTIVE_RESOURCE_ID', resourceId);
      const mainElementInTree = resourceId < 0;

      if(mainElementInTree) {
        this.$router.push({name: 'resources-list', params: {id: resourceId}});
      } else {
        this.$router.push({name: 'cms-general', params: {id: resourceId}});
      }
    },
    toggle(resourceId) {
      if (this.isFolder) {
        this.isOpen = !this.isOpen
      } else {
        this.$router.push({name: 'cms-general', params: {id: resourceId}});
      }
    },
    toggleAutomatic(resourceId) {
      this.isOpen = false;

      if (this.isFolder) {
        this.isOpen = !this.isOpen
      } else {
        this.$router.push({name: 'cms-general', params: {id: resourceId}});
      }
    },
    changeType() {
      if (!this.isFolder) {
        this.model.children = []
        this.addChild()
        this.isOpen = true
      }
    }
  }
}
</script>