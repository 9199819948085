<template>
  <b-modal
      @ok="importXmlFile"
      ref="import-modal"
      @hidden="resetModal"
      @cancel="resetModal"
      :ok-disabled="isExportRequest"
      :title="$t('cms.import_modal.title')"
      :cancel-title="$t('cms.import_modal.btn.cancel')"
      :ok-title="$t('white-label.btn.import')"
      size="lg"
      scrollable
      >
    <div style="min-height: 400px;">
      <b-form-group
          :label="$t('cms.import_modal.parent_list')"
          label-for="parentList"
      >
        <multiselect
            id="parentList"
            :options="availableParents"
            v-model="parent"
            :multiple="false"
            label="title"
            track-by="id"
            :placeholder="$t('cms.import_modal.placeholder.parent_list')"
        >
        </multiselect>
      </b-form-group>
      <b-form-group :label="$t('form.label.attach_file')" label-cols-sm="5">
        <b-form-file
            v-model="file"
            :placeholder="$t('import.file.choose_label')"
            :drop-placeholder="$t('import.file.drop_placeholder')"
            :browse-text="$t('import.file.browse_text')"
            id="file" ref="file"></b-form-file>
        <p v-if="$v.file.$dirty && !$v.file.required" class="text-danger">{{ $t('error.required') }}</p>
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
import {mapState} from "vuex";
import {required} from "vuelidate/lib/validators";
import alertify from "alertifyjs";

export default {
  components: {
    Multiselect
  },
  props: {
    apiKey: {
      type: String
    }
  },
  data() {
    return {
      parent: null,
      file: null,
      isExportRequest: false
    };
  },
  validations: {
    file: {
      required
    }
  },
  computed: mapState({
    availableParents: (state) => state.cms.availableParents,
    locale: (state) => state.cms.locale
  }),
  watch: {
    locale() {
      this.$store.dispatch('cms/getAvailableParents', this.locale);
    }
  },
  mounted() {
    this.$store.dispatch('cms/getAvailableParents', this.locale);
  },
  methods: {
    importXmlFile(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.$v.$touch();
      let isValid = this.$v.$invalid === false;

      if (isValid) {
        this.isExportRequest = true;

        const payload = {
          locale: this.locale,
          id: this.parent ? this.parent.id : null,
          file: this.file
        }

        this.$store.dispatch('cms/importCmsToXml', payload).then(() => {
          alertify.success(this.$t('cms.import_modal.import_success'));

          setTimeout(() => {
            this.hideModal();
            this.$store.dispatch('cms/getResourceStructure');
            this.isExportRequest = false;
          }, 1000);
        }).catch(() => {
          alertify.error(this.$t('cms.import_modal.import_error'));
          this.isExportRequest = false;
        });
      }
    },
    showModal() {
      this.$refs['import-modal'].show();
    },
    resetModal() {
      this.parent = null;
      this.file = null;
      this.$v.$reset();
    },
    hideModal() {
      this.resetModal();
      this.$refs['import-modal'].hide();
    }
  }
}
</script>
