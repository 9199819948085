<template>
  <b-modal
      @ok="exportXmlFile"
      @cancel="resetModal"
      @hidden="resetModal"
      ref="export-modal"
      :ok-disabled="isExportRequest"
      :title="$t('cms.import_export_modal.title')"
      :cancel-title="$t('cms.import_export_modal.btn.cancel')"
      :ok-title="$t('white-label.btn.export')"
      size="lg"
      scrollable>
    <div style="min-height: 400px;">
      <b-form-group
          :label="$t('cms.import_export_modal.parent_list')"
          label-for="parentList"
      >
        <multiselect
            id="parentList"
            :options="availableParents"
            v-model="parent"
            :multiple="false"
            label="title"
            track-by="id"
            :placeholder="$t('cms.import_export_modal.placeholder.parent_list')"
        >
        </multiselect>
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
import {mapState} from "vuex";
import alertify from "alertifyjs";


export default {
  components: {
    Multiselect
  },
  props: {
    apiKey: {
      type: String
    }
  },
  data() {
    return {
      parent: null,
      isExportRequest: false
    };
  },
  computed: mapState({
    availableParents: (state) => state.cms.availableParents,
    locale: (state) => state.cms.locale
  }),
  watch: {
    locale() {
      this.$store.dispatch('cms/getAvailableParents', this.locale);
    }
  },
  mounted() {
    this.$store.dispatch('cms/getAvailableParents', this.locale);
  },
  methods: {
    exportXmlFile() {
      this.isExportRequest = true;

      const payload = {
        locale: this.locale,
        id: this.parent ? this.parent.id : null
      }

      this.$store.dispatch('cms/exportCmsToXml', payload).then(() => {
        this.isExportRequest = false;
        alertify.success(this.$t('cms.export_modal.export_success'));
      }).catch(() => {
        this.isExportRequest = false;
        alertify.error(this.$t('cms.export_modal.export_error'));
      });
    },
    showModal() {
      this.$refs['export-modal'].show();
    },
    resetModal() {
      this.parent = null;
    }
  }
}
</script>
